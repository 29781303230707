.Header {
  &__heading {
    background-color: var(--color-navy);

    grid-row: 1 / 2;
    grid-column: 1 / 2;

    line-height: 1.2;
    color: var(--color-white);
    text-align: center;

    display: grid;
    grid-template-rows: repeat(2, min-content);
    grid-gap: 2rem;
    justify-content: center;
    align-content: center;
    height: 100%;

    @media only screen and (max-width: $bp-large) {
      background-color: var(--color-blue);
      padding: 3rem 0;

      grid-column: 1 / -1;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(2, max-content);
    }

    @media only screen and (max-width: $bp-xsmall) {
      grid-gap: 1rem;
    }

    span {
      text-transform: uppercase;
      color: var(--color-teal);
    }
  }

  &__actions {
    background-color: var(--color-navy);

    height: 100%;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    justify-content: center;
    justify-items: center;
    align-content: center;

    display: grid;
    grid-gap: 3rem;
    align-content: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 3rem 0;
      grid-row: 3 / 4;
      grid-column: 1 / -1;

      grid-template-columns: repeat(2, 1fr);
      justify-items: end;

      .btn--submit {
        justify-self: start;
      }
    }
  }

  &__btn {
    @media only screen and (max-width: $bp-xsmall) {
      span {
        display: none;
      }
    }
  }
}

.Header__select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  grid-column: 1/-1;
  justify-self: center;
  padding: 2rem;
  background-color: transparent;
  outline: 0;
  border: 1px solid #fff;
  color: #fff;
  letter-spacing: 3px;
  font-weight: 300;
  font-size: 1.2rem;
  transition: all 0.2s ease-out;
  backface-visibility: hidden;
  cursor: pointer;
  border-radius: 5px;

  background-position: right 2rem center;
  padding-right: 4rem;
  background-image: url("data:image/svg+xml;charset=utf-8, \
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 40'> \
      <polygon points='0,0 60,0 30,40' style='fill:white;'/> \
    </svg>");

  @media only screen and (max-width: $bp-xsmall) {
    font-size: 1.6rem;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-teal);
    }
  }

  option {
    background-color: var(--color-navy);
    color: white;
    font-size: 2rem;
    text-align: center;
  }
}
