.Field {
    grid-row: 1 / span 2;
    grid-column: 2 / 3;

    @media only screen and (max-width: $bp-large) {
        grid-row: 2 / 3;
        grid-column: 2 / -1;
    }

    @media only screen and (max-width: $bp-medium) {
        grid-column: 1 / -1;
    }

    display: grid;
    grid-template-rows: repeat(4, 1fr);
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    padding: 2rem 0;

    background-color: var(--color-teal);
}

.FieldLine {
    &__label {
        text-align: center;
        line-height: 1;
        font-size: 11rem;
        letter-spacing: 30px;
        padding-left: 30px;
        font-weight: 900;
        text-transform: uppercase;
        color: var(--color-lightgrey);
        color: rgba(#f0f0f0, 0.25);

        @media only screen and (max-width: $bp-xlarge) {
            line-height: 1.2;
            font-size: 9rem;
            letter-spacing: 25px;
            padding-left: 25px;
        }

        @media only screen and (max-width: $bp-medium) {
            font-size: 8rem;
        }

        @media only screen and (max-width: $bp-small) {
            font-size: 3rem;
            line-height: 2.6;
        }
        
        @media only screen and (max-width: $bp-xsmall) {
            display: none;
        }

    }

    &__players {
        margin-top: -12rem;
        display: flex;
        justify-content: center;
        align-content: center;

        @media only screen and (max-width: $bp-small) {
            margin-top: 0;
        }
    }
}

.FieldCard {
    margin: 0 3rem;
    position: relative;
    color: var(--color-white);
    border-radius: 5px;
    width: 10rem;
    height: 13rem;

    @media only screen and (max-width: $bp-small) {
        margin: 0 2rem;
        width: 7rem;
        height: 10rem;
    }

    @media only screen and (max-width: $bp-xsmall) {
        margin: 0 1rem;
        width: 6rem;
        height: 9rem;
    }

    &--selected &__image {
        border: 3px solid deeppink;
    }

    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(
            0deg,
            rgba(0, 0, 96, 1) 0%,
            rgba(0, 55, 147, 0.9) 10%,
            rgba(0, 99, 179, 0.8) 20%,
            rgba(0, 99, 179, 0.5) 30%,
            transparent 50%
        );
        border-radius: 5px;
    }

    &__image-window {
        width: 100%;
        height: 13rem;
        overflow: hidden;
        border-radius: 5px;

        @media only screen and (max-width: $bp-small) {
            height: 10rem;
        }

        @media only screen and (max-width: $bp-xsmall) {
            height: 9rem;
        }
    }

    &__image {
        width: 100%;
        border: 3px solid var(--color-navy);
        &--default {
            border-radius: 5px;
        }
    }

    &__name {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 1;
        text-transform: uppercase;
        font-size: 1.4rem;
        padding: .5rem;
        word-break: break-word;
        text-align: center;
        line-height: 1.2;

        @media only screen and (max-width: $bp-xsmall) {
            font-size: 1rem;
        }
    }
}