.Nominees {
    grid-column: 1 / -1;
    padding: 3rem;
    background-image: linear-gradient(to right, var(--color-navy), var(--color-purple));

    display: grid;
    grid-template-rows: repeat(2, min-content);
    grid-row-gap: 5rem;

    &__heading {
        line-height: 1;
        color: var(--color-pink);
        text-align: center;
        text-transform: lowercase;
        letter-spacing: 6px;
        padding-left: 6px;

        @media only screen and (max-width: $bp-xsmall) {
            font-size: 3rem;
        }
    }

    &__content {
        grid-row: 2 / 3;
        
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, min-content));
        grid-gap: 2rem;
        justify-content: center;
        justify-items: center;
        transition: all .2s;
    }
}

.NomineeCard {
    width: 15rem;
    height: 20rem;
    position: relative;
    color: var(--color-white);
    border-radius: 5px;

    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(
            0deg,
            rgba(0, 0, 96, 1) 0%,
            rgba(0, 55, 147, 0.9) 10%,
            rgba(0, 55, 147, 0.7) 20%,
            rgba(0, 99, 179, 0.5) 30%,
            rgba(0, 99, 179, 0.3) 40%,
            rgba(0, 99, 179, 0.1) 50%,
            transparent 60%
        );
        border-radius: 5px;
    }

    &__image-window {
        width: 100%;
        height: 20rem;
        overflow: hidden;
        border-radius: 7px;
    }

    &__image {
        width: 100%;
    }

    &__name {
        position: absolute;
        width: 100%;
        bottom: 3.5rem;
        z-index: 1;
        text-transform: uppercase;
        font-size: 1.4rem;
        padding: 1rem;
        word-break: break-word;
        text-align: left;
        line-height: 1.2;
        letter-spacing: 3px;
        font-weight: 400;
    }

    &__club {
        position: absolute;
        width: 100%;
        bottom: 1rem;
        z-index: 1;
        padding: 1rem;
        font-size: 1.2rem;
        font-weight: 300;
        word-break: break-word;
        text-align: left;
        line-height: 1.2;
    }

    &__number {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        padding: 1rem;
        font-size: 2rem;
        font-weight: 400;
    }
}

.Nominee-enter {
opacity: 0;
}
.Nominee-enter-active {
opacity: 1;
transition: opacity 250ms;
}
.Nominee-exit {
opacity: 1;
}
.Nominee-exit-active {
opacity: 0;
transition: opacity 250ms;
}