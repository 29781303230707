.Modal {
    background-color: rgba(0,0,0, 0.8);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    &__content {
        background-color: var(--color-white);
        min-width: 30rem;
        padding: 1rem;

        display: grid;
        grid-template-rows: repeat(3, 1fr);
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &__heading {
        font-size: 4rem;
    }

    &__paragraph {
        font-size: 2rem;
    }

    &__button {
        background-color: var(--color-navy);
    }
}