.heading-1 {
    font-size: 6rem;
    
    @media only screen and (max-width: $bp-small) {
        font-size: 4rem;
    }
    @media only screen and (max-width: $bp-xsmall) {
        font-size: 3rem;
    }

}

.heading-2 {
    font-size: 5rem;
}

.btn {
    padding: 2rem;
    background-color: transparent;
    outline: 0;
    border: 1px solid #fff;
    text-transform: lowercase;
    color: #fff;
    letter-spacing: 3px;
    font-weight: 300;
    font-size: 1.2rem;
    transition: all .2s ease-out;
    backface-visibility: hidden;
    cursor: pointer;
    border-radius: 5px;

    @media (hover: hover) {
        &:hover:not([disabled]) {
            transform: translateY(-3px);
            box-shadow: var(--shadow-dark);
        }
    }

    @media only screen and (max-width: $bp-xsmall) {
        font-size: 1.6rem;
    }

    &::after {
        content: '';
        display: inline-block;
        height: 2rem;
        width: 2rem;
        vertical-align: middle;
        margin-left: 1rem;
        mask-size: cover;
        mask-repeat: no-repeat;
        background-repeat: no-repeat;
        background: var(--color-white);
        transition: all .2s;

        @media only screen and (max-width: $bp-giant) {
            height: 2.5rem;
            width: 2.5rem;
        }

        @media only screen and (max-width: $bp-xsmall) {
            margin-left: 0;
        }
    }

    @media (hover: hover) {
        &:hover:not([disabled])::after {
            transform: translateX(3px);
        }

        &--delete:hover:not([disabled]) {
            background-color: deeppink;
        }

        &--submit:hover:not([disabled]) {
            background-color: var(--color-teal);
        }
    }
    
    &--delete::after {
        mask: url(/images/svg/trash.svg);
    }

    &--submit::after {
        mask: url(/images/svg/paper-plane.svg);
    }

    &--done::after {
        mask: url(/images/svg/check.svg);
    }

    &:disabled {
        color: var(--color-lightgrey);
        border-color: currentColor;
        cursor: auto;

        &::after {
            background: currentColor;
        }
    }
}

.rounded-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: -5px;
    padding: .5rem;
    border-radius: 50px;
    outline: 0;
    border: none;
    width: 3rem;
    height: 3rem;
    font-size: 2rem;
    cursor: pointer;
    transition: all .2s;
    z-index: 10;
    backface-visibility: hidden;

    &:hover {
        transform: scale(1.1);
    }

    &__icon {
        display: block;
        position: absolute;
        width: 2rem;
        height: 2rem;
        backface-visibility: hidden;
        background-size: cover;

        &--add {
            background-image: url(/images/svg/plus.svg);
        }

        &--swap {
            background-image: url(/images/svg/cycle.svg);
        }

        &--close {
            background-image: url(/images/svg/cross.svg);
        }
    }
}

